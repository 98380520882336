<script lang='ts'>
  import { onMount } from 'svelte'
  import { link, navigate } from 'svelte-routing'

  import { JWT_COOKIE_EXPIRATION_DAYS } from '@/config/constants'

  import { API_PUBLIC_URL, API_URL, getCookie, setCookie } from '@/helpers/apiCall'
  import { setUserInterfaceLanguage } from '@/helpers/detectLanguage'
  import { _ } from '@/helpers/i18n'
  import { logger } from '@/helpers/logger'
  import { notifier } from '@/helpers/notifier'

  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import HomeSlides from '@/components/ui/HomeSlides.svelte'

  setUserInterfaceLanguage()

  let email: HTMLInputElement
  let pass: HTMLInputElement

  function handleSubmit () {
    fetch(API_URL + '/auth/login', {
      body: JSON.stringify({
        email: email.value,
        pass: pass.value
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((result) => {
        result.json().then((data) => {
          if (data.jwt) {
            setCookie('jwt', data.jwt, JWT_COOKIE_EXPIRATION_DAYS)
            const params = new URLSearchParams(window.location.search)
            const redirect = params.get('redirect')
            if (redirect && !redirect.includes('/login')) {
              navigate(redirect)
            } else {
              location.href = '/'
            }
          } else {
            notifier.error($_('auth.incorrectData'))
          }
        }).catch(e => {
          logger.error(e)
        })
      })
      .catch(() => {
        notifier.error($_('auth.error'))
      })

    return false
  }

  function socialLoginRedirect () {
    const urlParams = new URLSearchParams(window.location.search)
    const jwt = urlParams.get('jwt')
    const message = urlParams.get('message')?.toString() || ''
    if (jwt) {
      setCookie('jwt', jwt, JWT_COOKIE_EXPIRATION_DAYS)
      location.href = '/?message=' + encodeURIComponent(message)
    }
  }

  onMount(() => {
    const jwt = getCookie('jwt')
    if (jwt) {
      navigate('/')
    } else {
      socialLoginRedirect()
    }
  })
</script>

<HomeSlides />
<div class='login-page'>
  <MenuNotLogged />
  <form class='login-form' method='post' on:submit|preventDefault={handleSubmit}>
    <div class='login-header'>
      <h2>{$_('auth.loginTitle')}</h2>
      <p class='text1'>{$_('auth.loginTitleText')}</p>
    </div>
    <hr />
    <div class='input-data'>
      <label for='email'>{$_('auth.email')}</label>
      <input
        bind:this={email}
        id='email'
        class='email'
        placeholder={$_('auth.emailPlaceholder')}
        type='email'
        name='email'
      />
      <label for='pass'>{$_('auth.pass')}</label>
      <input
        bind:this={pass}
        id='pass'
        class='pass'
        placeholder={$_('auth.passPlaceholder')}
        type='password'
        name='pass'
      />
    </div>
    <a href='/forgot-password' style='display: flex;' use:link>{$_('auth.forgotPassword')}</a>
    <button class='btn' type='submit'>{$_('auth.login')}</button>
    <p class='horizontalLine'><span>{$_('auth.OR')}</span></p>
    <div class='social-login-buttons'>
      <a class='googleButton' href='{API_PUBLIC_URL}/auth/googleLogin.php'>
        <img alt='gmail-logo' src='/images/gmail-logo.svg' />{$_('auth.googleLogin')}</a>
      <a class='facebookButton' href='{API_PUBLIC_URL}/auth/facebookLogin.php'>
        <img alt='facebook-logo' src='/images/facebook-logo.svg' />{$_('auth.fbLogin')}</a>
    </div>
  </form>
  <p class='my-account'>{$_('auth.needAccount')}
    <a href='/register' use:link><span>{$_('auth.register')}</span></a>
  </p>
</div>

<style lang='scss'>
  hr {
    visibility: hidden;
  }

  label {
    font: var(--Medium-300);
    color: var(--Gray-Darker);
  }

  a {
    text-align: center;
  }
</style>
