<script lang='ts'>
  import { link } from 'svelte-routing'

  import { HTTP_STATUS_OK, JWT_COOKIE_EXPIRATION_DAYS } from '@/config/constants'

  import { API_PUBLIC_URL, API_URL, setCookie } from '@/helpers/apiCall'
  import { setUserInterfaceLanguage } from '@/helpers/detectLanguage'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'
  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import HomeSlides from '@/components/ui/HomeSlides.svelte'

  setUserInterfaceLanguage()

  let email: HTMLInputElement
  let pass: HTMLInputElement
  let name: HTMLInputElement

  function handleSubmit (): boolean {
    fetch(API_URL + '/auth/register', {
      body: JSON.stringify({
        email: email.value,
        name: name.value,
        pass: pass.value
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((result) => {
        result.json().then((data) => {
          if (result.status === HTTP_STATUS_OK) {
            setCookie('jwt', data.jwt, JWT_COOKIE_EXPIRATION_DAYS)
            location.href = '/?register'
          } else {
            notifier.warning($_(data.message))
          }
        })
      })
      .catch(() => {
        notifier.error($_('auth.error'))
      })

    return false
  }
</script>
<HomeSlides />
<div class='login-page'>
  <MenuNotLogged />
  <form class='login-form' method='post' on:submit|preventDefault={handleSubmit}>
    <div class='register-header'>
      <h2>{$_('auth.register')}</h2>
      <p class='text1' />
    </div>
    <hr />
    <div class='input-data'>
      <label for='name'>{$_('auth.name')}</label>
      <input
        bind:this={name}
        id='name'
        class='name'
        maxlength='122'
        placeholder={$_('auth.namePlaceholder')}
        required
        type='text'
        name='name'
      />
      <label for='email'>{$_('auth.email')}</label>
      <input
        bind:this={email}
        id='email'
        class='email'
        placeholder={$_('auth.emailPlaceholder')}
        required
        type='email'
        name='email'
      />
      <label for='pass'>{$_('auth.pass')}</label>
      <input
        bind:this={pass}
        id='pass'
        class='pass'
        maxlength='122'
        placeholder={$_('auth.passPlaceholder')}
        required
        type='password'
        name='pass'
      />
      <p class='passInfo'>
        <span><Icon icon='Info' size={16} weight='fill' /></span>{$_('auth.passRecommendation')}
      </p>
    </div>
    <button type='submit'>{$_('auth.signUp')}</button>
    <p class='horizontalLine'><span>{$_('auth.OR')}</span></p>
    <div class='social-login-buttons'>
      <a class='googleButton' href='{API_PUBLIC_URL}/auth/googleLogin.php'>
        <img alt='gmail-logo' src='/images/gmail-logo.svg' />{$_('auth.googleReg')}</a>
      <a class='facebookButton' href='{API_PUBLIC_URL}/auth/facebookLogin.php'>
        <img alt='facebook-logo' src='/images/facebook-logo.svg' />{$_('auth.fbReg')}</a>
    </div>
  </form>
  <p class='my-account'>{$_('auth.alreadyHaveAccount')}
    <a href='/login' use:link><strong>{$_('auth.login')}</strong></a>
  </p>
</div>

<style lang='scss'>
  hr {
    visibility: hidden;
  }

  label {
    font: var(--Medium-300);
    color: var(--Gray-Darker);
  }

  a {
    text-align: center;
  }
</style>
