<script lang='ts'>
  import { link } from 'svelte-routing'

  import { HTTP_STATUS_OK, JWT_COOKIE_EXPIRATION_DAYS } from '@/config/constants'

  import { API_URL, setCookie } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import HomeSlides from '@/components/ui/HomeSlides.svelte'

  let pass: string,
    confirmation: string

  function handleReset () {
    const MIN_REQUIRED_PASSWORD_LENGTH = 6
    const USER_REDIRECTION_TIME = 3e3
    if (pass !== confirmation) {
      notifier.warning($_('auth.passwordsNotMatch'))
      return false
    }
    if (pass.length < MIN_REQUIRED_PASSWORD_LENGTH) {
      notifier.warning($_('auth.passwordTooShort'))
      return false
    }
    fetch(API_URL + '/auth/verifyEmail', {
      body: JSON.stringify({
        confirmation,
        pass,
        url: location.search
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((result) => {
        if (result.status === HTTP_STATUS_OK) {
          result.json().then(data => {
            setCookie('jwt', data.jwt, JWT_COOKIE_EXPIRATION_DAYS)
            notifier.success($_('auth.passwordChanged'))
            setTimeout(() => {
              location.href = '/?notify'
            }, USER_REDIRECTION_TIME)
          })
        } else {
          result.json().then(data => {
            notifier.warning($_(data.message))
          })
        }
      })
      .catch(() => {
        alert($_('auth.incorrectData'))
      })

    return false
  }

  const savePassword = () => {
    handleReset()
  }
</script>

<HomeSlides />
<div class='login-page'>
  <MenuNotLogged />
  <form class='login-form' method='post' on:submit|preventDefault={savePassword}>
    <div class='login-header'>
      <h2>{$_('auth.resetPassword')}</h2>
      <p class='text1'>{$_('auth.verifyTitleText')}</p>
    </div>
    <hr />
    <div class='input-data'>
      <label for='pass'>{$_('auth.newPassword')}</label>
      <input
        id='pass'
        class='pass'
        placeholder={$_('auth.passPlaceholder')}
        type='text'
        bind:value={pass}
        name='pass'
      />
      <label for='pass_confirm'>{$_('auth.confirmPassword')}</label>
      <input
        id='pass_confirm'
        class='pass'
        placeholder={$_('auth.passPlaceholder')}
        type='text'
        bind:value={confirmation}
        name='pass2'
      />
    </div>
    <input type='submit' value={$_('save')} />
  </form>
  <p class='my-account'>{$_('auth.needAccount')}
    <a href='/register' use:link><span>{$_('auth.register')}</span></a>
  </p>
</div>

<style lang='scss'>
  hr {
    visibility: hidden;
  }

  label {
    font: var(--Medium-300);
    color: var(--Gray-Darker);
  }

  a {
    text-align: center;
  }
</style>
